.accordion {
  &-header:hover {
    background-color: $table-hover-bg;
  }

  &-inner {
    & + & {
      border-top: 1px solid $border-color-transparent;
    }
  }
}

.badge {
  &-bordered {
    background-color: transparent;
    border: 1px solid darken($light, 10%);
    color: $body-color;
  }

  &-danger {
    border-color: $danger !important;
    color: $danger !important;
  }

  &-primary {
    border-color: $primary !important;
    color: $primary !important;
  }

  &-success {
    border-color: $success !important;
    color: $success !important;
  }

  &.duration {
    min-width: 3.5rem;
  }
}

.bg {
  &-lighter {
    background-color: $lighter;
  }
}

.dropdown-toggle {
  &.open::after {
    transform: rotate(135deg);
    vertical-align: 0;
  }
}

.header {
  &-primary {
    background-color: $primary;
    color: rgba($white, 0.52) !important;

    .nav-link {
      &:hover,
      &.active {
        color: $white;
      }
    }
  }
}

.add-account-btn {
  color: $white;
  background-color: $primary;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin: 0.5rem 0.5rem;
  outline: none;
}

.add-account-btn:focus {
  outline: none;
}

.add-account-btn:hover {
  background-color: $primary-dark;
  color: $white;
}

.highlighter {
  &-attr {
    position: relative;
  }

  &-fold-arrow {
    cursor: pointer;
    display: block;
    padding: 0.4em;
    position: absolute;
    top: 0;
    left: -1.2em;
    line-height: 0;

    &::before {
      content: '';
      display: block;
      width: 0.36em;
      height: 0.36em;
      border-bottom: 1px solid;
      border-left: 1px solid;
      transform: rotate(-45deg);
    }

    &.folded::before {
      transform: rotate(-130deg);
    }
  }

  &-line-num {
    display: inline-block;
    opacity: 0.26;
    width: 2em;
  }

  &-rest {
    cursor: pointer;
  }
}

.theme-dark {
  $azure: #a8e1fe;
  $lighter: #112736;

  .badge {
    &-bordered {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
    }

    &-primary {
      border-color: $azure !important;
      color: $azure !important;
    }
  }

  .bg {
    &-lighter {
      background-color: $lighter;
    }
  }

  .header {
    &-primary {
      background-color: $dark-mode-darken;
      color: $text-muted !important;

      .nav-link {
        &:hover,
        &.active {
          color: $azure;
        }
      }
    }
  }
}

.accnt-list {
  display: inline-block;
  padding: 1rem 0.5rem;
  background-color: $body-color;
  font-weight: bold;
  letter-spacing: 0.02rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  text-decoration: none;
  box-shadow: rgba(1, 39, 65, 0.418) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  .card {
    background-color: inherit;
  }
  &:hover {
    cursor: pointer;
    box-shadow: rgba(32, 134, 202, 0.418) 0px 0px 1px 1px, rgba(32, 134, 202, 0.418) 0px 2px 4px;
    background-color: $primary-dark;
  }
  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
    text-decoration: none;
    color: inherit;
  }
  strong {
    color: #979797;
    font-weight: 400;
    letter-spacing: normal;
  }
}

.nav-link:hover {
  color: $primary;
}