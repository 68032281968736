.theme-dark {
  @import '~highlight.js/scss/stackoverflow-dark';

  .bg-light {
    background-color: $dark-mode-lighten !important;
  }

  .bg-dark {
    background-color: $dark-mode-darken !important;
  }

  .btn-close {
    $svg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#ffffff' viewBox='0 0 16 16'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");

    background-image: escape_svg($svg);
  }

  .hljs-attribute {
    color: #c59bc1;
  }

  .modal-backdrop.show {
    background-color: $black;
    opacity: 0.34;
  }

  .table {
    thead th {
      background-color: $dark-mode-darken;
    }
  }
}
